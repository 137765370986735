<template>
    <div class="search-header-wrap">
    <div  class="saas_search">
        <el-input v-model="searchStr"  placeholder="指标项名，如：地区生产总值" @keydown.enter="pushSearch()">
        <template #append>
            <el-button  @click="pushSearch()"  >搜索</el-button>
                <!-- <el-link :underline="false" style="font-size:16px;padding:8px 30px;" :href="'/#/'+type+'search?key='+searchStr">搜索</el-link>  -->
        </template>
        </el-input>
    </div>
        <ul  class="search-tabs-wrap clearfix">
            <li v-for="item in tabList" :key="item.name" @click="pushSearch(item.path,item.name)" class="tab-item" :class="{ 'active-tab-item': item.isSel}">
                <span >{{item.meta.title+"("+item.value+")"}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import {getUserRightFun} from '@/http/basicsApi'
//import { ElMessage  } from 'element-plus'
//import { useRoute } from 'vue-router'
export default {
    setup() {
        const route =useRoute(); //useRoute();//当前路由
        const router=useRouter();//路由
        let searchStr=ref(route.query['key']?route.query['key']:'');//ref(props.search)//搜索内容
        let tabList=reactive(router.getRoutes().find(t=>t.name.toLowerCase()=='search').children)
        .sort(function (a, b) {//升序
            if(a.meta.sort==null || b.meta.sort==null) return -1
            return a.meta.sort - b.meta.sort;
        });
        tabList.forEach(el => {
            el.isSel=false;
            if(el.name.toLowerCase()=='econosearch'){
                el.value='600w+'
            }
            else if(el.name.toLowerCase()=='ecoindustrysearch'){
                el.value='300w+'
            }
            else if(el.name.toLowerCase()=='microecosearch'){
                el.value='3000w+'
            }
            else if(el.name.toLowerCase()=='ecoarticlesearch'){
                el.value='20w+'
            }
            if(route.name.toLowerCase()==el.name.toLowerCase()){
                el.isSel=true;
            }
        });
        //搜索跳转
        const pushSearch= async(path,name)=>{
            if(path && name){
                console.log('单击');
                console.log(path);
                console.log(name);
                if(await getUserRightFun(name,0)){
                    router.push({path:path,query:{key:searchStr.value}});
                }
            }
            else{
                router.push({path:route.path,query:{key:searchStr.value}});
            }
            
        }
        return{
            searchStr,
            tabList,
            pushSearch
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.search-header-wrap{
    padding-top:30px;
    background: rgba($themeColor,0.1);
}
.saas_search{
    width:50%;
    margin:auto;
    button{
        background: $themeColor;
        color: #fff;
        border-radius: 0;
    }
}
::v-deep .el-input__inner{
    border: 2px solid $themeColor !important;
}
.search-tabs-wrap{
    margin-top: 20px;
    width: 60%;
    margin-left: 20%;
}
.search-tabs-wrap li.tab-item{
    float: left;

    margin: 0 40px 0 0;
    font-size: 16px;
    position: relative;
    height: 37px;
    font-size: 16px;
    letter-spacing: .16px;
    text-align: left;
    line-height: 24px;
    padding: 0 5px;
    box-sizing: border-box;
}
.search-header-wrap .search-tabs-wrap .active-tab-item, .search-header-wrap .search-tabs-wrap li.tab-item:hover {
    cursor: pointer;
    color: $specialtext-color;
    border-bottom: 2px solid $specialtext-color;
}
</style>