<template>
  <div>
    <Header></Header>
    <div v-if='isRight'>
      <search-header></search-header>
      <router-view></router-view>
      <!-- router-view 当你的路由path 与访问的地址相符时，会将指定的组件替换该 router-view  一组router-view代表一个组件-->
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import SearchHeader from '@/components/SearchHeader'
import {getUserRightFun} from '@/http/basicsApi'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
    components:{
      Header,
      SearchHeader
    },
    setup() {
      const route=useRoute();
      let isRight=ref(false);
        //判断是否有权限访问此页面
        getUserRightFun(route.name,0).then(res=>{
            isRight.value=res;
        })
        return{
          isRight
        }
    },
}
</script>